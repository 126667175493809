import { motion, stagger, useAnimate, useInView } from "framer-motion";
import { useEffect } from "react";

export const IntroBanner = () => {
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope, { once: true });

  useEffect(() => {
    if (isInView) {
      animate(
        ".animate-item",
        {
          opacity: [0, 1],
          y: [20, 0],
        },
        { delay: stagger(0.2, { startDelay: 0.3 }) }
      );
    }
  }, [animate, isInView]);

  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      ref={scope}
    >
      <div className="mx-auto sm:text-center">
        <div className="max-w-full mb-10 md:mx-auto sm:text-center md:mb-12">
          <div>
            <p className="animate-item inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-primary-main">
              IDMC CROSSWorld Conference 2023
            </p>
          </div>
          <h2 className="animate-item  max-w-full text-3xl font-bold leading-none tracking-wider text-textColor-light sm:text-6xl  mb-6 font-sans sm:leading-none">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-textColor-light lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="text-textColor-light">
                Rethinking Discipleship
              </span>
            </span>
          </h2>
          <div className="flex flex-col items-center justify-center my-12 animate-item">
            <iframe
              className="aspect-video w-[100%] md:w-[70%]"
              src="https://www.youtube.com/embed/LHubr-Xfono"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p className="max-w-[100%] md:max-w-[70%] text-base text-textColor-light md:text-xl self-center mx-auto animate-item ">
            What is the greatest crisis of discipleship in the life of the
            Church today? Is it the crisis of spiritual consumerism, carnality,
            prayerlessness, leadership or all of the above?
            <br />
            <br />
            Rev Edmund Chan suggests that the greatest crisis is that we cannot
            see the crisis! We cannot define the very terms of discipleship
            Jesus has given us: “If any man come after me, let him deny himself,
            take up his cross daily and follow me.’ (Luke 9:23).
            <br />
            <br />
            We have to radically recapture discipleship into the life of the
            Church today. We have to rethink discipleship. To do so, we have to
            rethink the myths about discipleship: “Discipleship is important,
            but it doesn’t work.” “It works for other churches, but it doesn’t
            work for my church.” “We are too busy; discipleship is not the thing
            for us.” “Discipleship is too demanding.”
            <br />
            <br />
            If you are trying to understand why it doesn’t work, learn the
            fundamental principles by which the lordship of Jesus and the
            dynamic Kingdom life of discipleship can be realised in your life
            and in your church. There’s a truth, a fundamental call, a spiritual
            dynamic that will change your life and your church!
          </p>
        </div>
      </div>
    </div>
  );
};
