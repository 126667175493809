import React, { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { UserAuth } from "../../context/AppContext";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, logoutUser, showToast } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logoutUser();
    navigate("/");
    showToast("Logout Successfully!");
  };
  return (
    <div className="bg-white">
      <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="relative flex items-center justify-between">
          <NavLink
            to="/"
            aria-label="IDMC Me and My House"
            title="IDMC Me and My House"
            className="inline-flex items-center"
          >
            <img src={"/images/IDMC_logo.png"} alt="logo" width="60" />
            <span className="ml-2 text-xl font-bold  tracking-wide text-white uppercase"></span>
          </NavLink>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            <li>
              <NavLink
                to="/"
                aria-label="Our product"
                title="Our product"
                className="font-medium tracking-wide text-textColor-dark transition-colors duration-200 hover:text-accent-main"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faq"
                aria-label="Our product"
                title="Our product"
                className="font-medium tracking-wide text-textColor-dark transition-colors duration-200 hover:text-accent-main"
              >
                FAQs
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/guide"
                aria-label="About us"
                title="About us"
                className="font-medium tracking-wide text-textColor-dark transition-colors duration-200 hover:text-accent-main"
              >
                Guide
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/get-tickets"
                className="inline-flex rounded-full items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 shadow-md bg-accent-main hover:bg-accent-light focus:shadow-outline focus:outline-none"
                aria-label="Register"
                title="Register"
              >
                Register
              </NavLink>
            </li>
            {user?.email && (
              <li>
                <HeadlessMenu as="div" className="relative">
                  <div>
                    <HeadlessMenu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user HeadlessMenu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user?.photoURL}
                        alt=""
                      />
                    </HeadlessMenu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <HeadlessMenu.Items className="origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-xl py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <HeadlessMenu.Item>
                        {({ active }) => (
                          <NavLink
                            to="/my-tickets"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            My Tickets
                          </NavLink>
                        )}
                      </HeadlessMenu.Item>
                      <HeadlessMenu.Item>
                        {({ active }) => (
                          <span
                            tabIndex={0}
                            onClick={handleLogout}
                            role="button"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "cursor-pointer block px-4 py-2 text-sm  text-gray-700"
                            )}
                          >
                            Sign out
                          </span>
                        )}
                      </HeadlessMenu.Item>
                    </HeadlessMenu.Items>
                  </Transition>
                </HeadlessMenu>
              </li>
            )}
          </ul>
          <div className="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              className="p-2 -mr-1 z-50 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-blue-50 focus:bg-blue-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full z-50">
                <div className="p-5 bg-white border rounded shadow-xl">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <a
                        href="/"
                        aria-label="Company"
                        title="Company"
                        className="inline-flex items-center"
                      >
                        <img
                          src={"/images/IDMC_logo.png"}
                          alt="logo"
                          width="60"
                        />
                        <span className="ml-2 text-xl font-bold font-sans tracking-wide text-gray-800 uppercase"></span>
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4">
                      <li>
                        <NavLink
                          to="/"
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                          aria-label="Our product"
                          title="Our product"
                          className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-primary-main"
                        >
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/faq"
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                          aria-label="Our product"
                          title="Our product"
                          className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-primary-main"
                        >
                          FAQs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/guide"
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                          aria-label="About us"
                          title="About us"
                          className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-primary-main"
                        >
                          Guide
                        </NavLink>
                      </li>
                      {user?.uid && (
                        <>
                          <li>
                            <NavLink
                              to="/my-tickets"
                              onClick={() => {
                                setIsMenuOpen(false);
                              }}
                              aria-label="About us"
                              title="About us"
                              className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-primary-main"
                            >
                              My Tickets
                            </NavLink>
                          </li>
                          <li>
                            <span
                              tabIndex={0}
                              onClick={() => {
                                setIsMenuOpen(false);
                                handleLogout();
                              }}
                              role="button"
                              className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-primary-main"
                            >
                              Sign out
                            </span>
                          </li>
                        </>
                      )}
                      <li>
                        <NavLink
                          to="/get-tickets"
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                          className="inline-flex w-full rounded-full items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 shadow-md bg-accent-main hover:bg-accent-light focus:shadow-outline focus:outline-none"
                          aria-label="Register"
                          title="Register"
                        >
                          Register
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
