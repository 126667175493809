import React, { useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import ConferenceSchedule from "../../components/ConferenceSchedule/ConferenceSchedule";
import { IntroBanner } from "../../components/IntroBanner/IntroBanner";
import "pure-react-carousel/dist/react-carousel.es.css";
import FAQ from "../FAQ/FAQ";
import { NavLink } from "react-router-dom";
import { motion, stagger, useAnimate, useInView } from "framer-motion";
import Speaker from "../../components/Speaker/Speaker";

export const Homepage = () => {
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope, { once: true });

  useEffect(() => {
    if (isInView) {
      animate(
        ".faq-item",
        {
          opacity: [0, 1],
          y: [20, 0],
        },
        { delay: stagger(0.2, { startDelay: 0.3 }) }
      );
    }
  }, [animate, isInView]);

  return (
    <>
      <Banner />
      <div
        className=" min-h-screen flex items-center"
        style={{
          backgroundImage:
            "repeating-linear-gradient(45deg, rgba(97,97,97,0.1) 0px, rgba(97,97,97,0.1) 2px, transparent 2px, transparent 4px), linear-gradient(90deg, rgb(43,43,43), rgb(43,43,43))",
        }}
      >
        <IntroBanner />
      </div>

      <Speaker />
      <ConferenceSchedule />
      <div
        className="max-w-screen-xl px-4 py-10 sm:px-6 lg:px-8 lg:py-16 mx-auto faq-section-container"
        ref={scope}
        style={{
          backgroundImage:
            "repeating-linear-gradient(45deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),repeating-linear-gradient(135deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),linear-gradient(90deg, hsl(256,7%,84%),hsl(256,7%,84%))",
        }}
      >
        <div className="grid md:grid-cols-6 gap-6">
          <div className="md:col-span-2">
            <div className="max-w-xs">
              <h2 className="text-2xl mx-4 font-bold md:text-3xl md:leading-tight faq-item">
                Frequently
                <br />
                Asked Questions
              </h2>
              <p className="mx-4 mt-1 hidden md:block text-gray-600 pt-4  faq-item">
                Answers to the most frequently asked questions.
              </p>

              <NavLink
                to="/get-tickets"
                className="cursor-pointer select-none m-4 faq-item inline-flex rounded-full items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 shadow-md bg-accent-main hover:bg-accent-light focus:shadow-outline focus:outline-none"
                aria-label="Register"
                title="Register"
              >
                Register
              </NavLink>
            </div>
          </div>

          <div className="md:col-span-4">
            <FAQ hideTitle={true} />
          </div>
        </div>
      </div>
    </>
  );
};
