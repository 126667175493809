import React, { useEffect, useState } from "react";
import PageBanner from "../../components/PageBanner/PageBanner";
import { motion, stagger, useAnimate, useInView } from "framer-motion";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border rounded shadow-sm faq-item">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none bg-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium text-start">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full bg-white">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700 w-100%">{children}</p>
        </div>
      )}
    </div>
  );
};

const items = [
  {
    title:
      "Maaari bang magregister online ngunit magbabayad in cash sa CROSSWorld Church Central?",
    content: (
      <>
        Oo, siguraduhing sa taong nakatalaga lamang magbibigay ng payment.
        Picture-an ang resibo na ibibigay at i-upload sa website.
        <br /> <br /> Look for Ms. Avic Vidar <br />
        Wednesday, Thursday and Saturday ( 8 am - 5 pm) <br />
        Sunday ( 12 noon - 4 pm)
      </>
    ),
  },
  {
    title: "Maaari bang mag-register ng higit sa 1 tao gamit ang 1 account?",
    content:
      'Oo, pindutin lamang ang "+" or "-" sign sa tabi ng "No. of tickets". Pagkatapos ay pindutin ang "Proceed to Payment". Siguraduhin na tama ang bilang ng ticket na nais mong i-register.',
  },
  {
    title: "Maaari ba akong mag-register sa araw mismo ng Conference?",
    content:
      "Hindi, magsasara ang registration kapag naabot na ang limit na 700 katao kaya ang lahat ay inaanyayahang mag-register hanggang October 15, 2023.",
  },
  {
    title: "Ang akin bang conference ticket ay transferable?",
    content: "Oo, maaaring ibigay ang iyong fully-paid ticket sa iba.",
  },
  {
    title:
      "Maaari ba akong makatanggap ng refund kapag hindi ako naka-attend ng Conference?",
    content:
      "Hindi, ang iyong conference ticket ay transferable ngunit hindi refundable.",
  },
  {
    title: "Anu-ano ang mga paraan para makapagbayad ng Conference ticket?",
    content: (
      <>
        Maaaring magbayad sa pamamagitan ng Bank Deposit o Bank transfer matapos
        maisubmit ang registration form:
        <br />
        <br />
        <b>Via Bank Deposit:</b>
        <br />
        <b>Bank:</b> RCBC
        <br />
        <b>Account Name:</b> CHRIST ENTHRONED CHURCH INC
        <br />
        <b>Account Number:</b> 0-048-00115-8
        <br />
        <br />
        <b>Via GCASH Bank transfer:</b>
        <br />
        On the Gcash App, click on Bank Transfer, and select RCBC. Kindly refer
        to the information below for the details of the account.
        <br />
        <b>Account Number:</b>CHRIST ENTHRONED CHURCH INC
        <br /> <b>Account Number:</b> 0-048-00115-8
      </>
    ),
  },
  {
    title: "Maaari bang magdala ng bata sa araw ng Conference?",
    content:
      "Hindi pinapayagan ang pagdadala ng bata edad 12 pababa sa araw ng Conference para sa kanilang kaligtasan.",
  },
  {
    title: "Maaari bang kumain sa loob ng Conference hall?",
    content:
      "Hindi maaaring kumain sa loob ng Conference hall. May nakalaang mga area sa bakuran ng church kung saan maaaring kumain. ",
  },
  {
    title:
      "Ano ang dapat kong ipakita sa registration area sa araw ng Conference?",
    content: (
      <>
        Ipakita at i-scan ang iyong Conference ticket/QR code. Maaari itong
        iprint o kaya naman ay i-save sa iyong cellphone o any device. Mahigpit
        na ipapatupad ang <b>"No Conference Ticket/QR code, No entry" </b>
        Policy.
      </>
    ),
  },
  {
    title: "Saan ko maaaring makita ang aking Conference ticket?",
    content:
      "Bisitahin ang IDMC CROSSWorld 2023 website at pindutin ang iyong profile. Pagkatapos, pindutin ang 'My Ticket' para makita ang iyong payment status at conference ticket o QR code.",
  },
  {
    title: "Paano pumunta sa CROSSWorld Church?",
    content: (
      <>
        Address: CROSSWorld Center, L4468 Bulak St., Malhacan, Meycauayan City,
        Bulacan o visitahin ang link na ito:
        <a
          href="https://goo.gl/maps/XtW7ba5rSF9rea2Q9"
          target="_blank"
          rel="noreferrer"
          className="mt-6 mb-6 block text-primary-main hover:text-primary-main underline"
        >
          {" "}
          Google Map Direction
        </a>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.575645301513!2d120.9617551769052!3d14.736567585766045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b3b559629b1d%3A0x67cb649c0a1e7eab!2sCROSSWorld%20Center!5e0!3m2!1sen!2sph!4v1688645904029!5m2!1sen!2sph"
          width="100%"
          height="450"
          title="CROSSWorld Church Location"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </>
    ),
  },
];

const english = [
  {
    title:
      "Can I register online and pay in cash at CROSSWorld Church Central?",
    content: (
      <>
        Yes, just make sure to pay only to the designated person who will accept
        cash payments. Take a picture of the receipt that will be given and
        upload it on the website.
        <br /> <br /> Look for Ms. Avic Vidar <br />
        Wednesday, Thursday and Saturday ( 8 am - 5 pm) <br />
        Sunday ( 12 noon - 4 pm)
      </>
    ),
  },
  {
    title: "Can I register more than 1 person using 1 account?",
    content:
      'Yes, simply click the "+" or "-" sign next to "No. of tickets". Then click "Proceed to Payment". Make sure the number of tickets you want to register is correct.',
  },
  {
    title: "Can I register on the day of the Conference?",
    content:
      "No, registration will close once the limit of 700 participants is reached. Everyone is encouraged to register until October 15, 2023.",
  },
  {
    title: "Is my conference slot transferable?",
    content: "Yes, you can transfer your fully-paid slot to someone else.",
  },
  {
    title: "Can I receive a refund if I don't attend the Conference?",
    content: "No, your conference ticket is transferable but not refundable.",
  },
  {
    title: "What are the payment methods for the Conference ticket?",
    content: (
      <>
        You can pay through Bank Deposit or Bank transfer after submitting the
        registration form:
        <br />
        <br />
        <b>Via Bank Deposit:</b>
        <br />
        <b>Bank:</b> RCBC
        <br />
        <b>Account Name:</b> CHRIST ENTHRONED CHURCH INC
        <br />
        <b>Account Number:</b> 0-048-00115-8
        <br />
        <br />
        <b>Via GCASH Bank transfer:</b>
        <br />
        On the Gcash App, click on Bank Transfer, and select RCBC. Kindly refer
        to the information below for the details of the account.
        <br />
        <b>Account Number:</b>CHRIST ENTHRONED CHURCH INC
        <br /> <b>Account Number:</b> 0-048-00115-8
      </>
    ),
  },
  {
    title: "Can I bring a child to the Conference?",
    content:
      "Bringing children aged 12 and below on the day of the Conference is not allowed for their safety.",
  },
  {
    title: "Can I eat inside the Conference hall?",
    content:
      "Eating inside the Conference hall is not allowed. There are designated areas where you can eat.",
  },
  {
    title:
      "What should I present at the registration area on the day of the Conference?",
    content: (
      <>
        Present and scan your Conference ticket/QR code. You can either print it
        or save it on your cellphone or any device. The{" "}
        <b>"No Conference Ticket/QR code, No entry"</b> Policy will be strictly
        implemented.,
      </>
    ),
  },
  {
    title: "Where can I find my Conference ticket?",
    content:
      "Visit the IDMC CROSSWorld 2023 website and click on your profile. Then, click on 'My Ticket' to see your payment status and conference ticket or QR code.",
  },
  {
    title: "How do I get to CROSSWorld Church?",
    content: (
      <>
        Address: CROSSWorld Center, L4468 Bulak St., Malhacan, Meycauayan City,
        Bulacan or visit this link:
        <a
          href="https://goo.gl/maps/XtW7ba5rSF9rea2Q9"
          target="_blank"
          rel="noreferrer"
          className="mt-6 mb-6 block text-primary-main hover:text-primary-main underline"
        >
          Google Map Direction
        </a>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.575645301513!2d120.9617551769052!3d14.736567585766045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b3b559629b1d%3A0x67cb649c0a1e7eab!2sCROSSWorld%20Center!5e0!3m2!1sen!2sph!4v1688645904029!5m2!1sen!2sph"
          width="100%"
          height="450"
          title="CROSSWorld Church Location"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </>
    ),
  },
];

export default function FAQ({ hideTitle = false }) {
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope, { once: true });

  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    if (isInView) {
      animate(
        ".faq-item",
        {
          opacity: [0, 1],
          y: [20, 0],
        },
        { delay: stagger(0.2, { startDelay: 0.3 }) }
      );
    }
  }, [animate, isInView]);

  return (
    <>
      {!hideTitle && <PageBanner />}
      <div
        className={`px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ${
          !hideTitle ? "py-16 lg:py-20" : ""
        }`}
      >
        <div
          className="max-w-xl sm:mx-auto lg:max-w-3xl"
          {...(!hideTitle && { ref: scope })}
        >
          {!hideTitle && (
            <div className="flex flex-col mb-8 sm:text-center">
              <div className="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                  Frequently Asked Questions
                </h2>
                <p className="text-base text-gray-700 md:text-lg"></p>
              </div>
            </div>
          )}
          <div className="grid justify-end">
            <div className="inline-flex self-end p-2 rounded-md  bg-gray-300 mb-4">
              <span
                onClick={() => setIsEnglish(false)}
                className={`transition-all px-4 py-2 rounded-l-md  cursor-pointer ${
                  !isEnglish
                    ? "bg-primary-light bg-accent-light text-white"
                    : ""
                }`}
              >
                Filipino
              </span>
              <span
                onClick={() => setIsEnglish(true)}
                className={`transition-all px-4 py-2 rounded-r-md cursor-pointer ${
                  isEnglish ? "bg-primary-light bg-accent-light text-white" : ""
                }`}
              >
                English
              </span>
            </div>
          </div>
          <div className="space-y-4">
            {isEnglish
              ? english.map((e) => <Item title={e.title}>{e.content}</Item>)
              : items.map((e) => <Item title={e.title}>{e.content}</Item>)}
          </div>
        </div>
      </div>
    </>
  );
}
