import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="bg-black">
      <div className=" px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <a
              href="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <img src={"/images/cw_logo.png"} alt="logo" width="50" />
              <span className="ml-2 text-xl font-bold tracking-wide text-white uppercase">
                IDMC CROSSWORLD
              </span>
            </a>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm text-white">
                {" "}
                IDMC CROSSWorld 2023 focuses on Rethinking Discipleship in the
                Church. The conference helps participants understand the true
                terms of discipleship and dispel common myths. It aims to bring
                a radical recapturing of discipleship and experience the
                transformative power of following Jesus.
              </p>
              {/* <p className="mt-4 text-sm text-white">
                Eaque ipsa quae ab illo inventore veritatis et quasi architecto
                beatae vitae dicta sunt explicabo.
              </p> */}
              IDMC
            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-base font-bold tracking-wide text-gray-100">
              Contacts
            </p>
            <div className="flex">
              <p className="mr-1 text-white">Phone:</p>
              <a
                href="tel:044-308-8622"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-white hover:text-underline"
              >
                (044) 308 8622
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 text-white">Email:</p>
              <a
                href="mailto:idmccrossworld@gmail.com"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-white hover:text-underline"
              >
                idmccrossworld@gmail.com
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 text-white">Address:</p>
              <a
                href="https://goo.gl/maps/8wLJXafdAhRnfMeU7"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-white hover:text-underline"
              >
                CROSSWorld Center, L4468 Bulak St., Malhacan, Meycauayan City,
                Bulacan
              </a>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide text-gray-100">
              Social
            </span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="https://www.youtube.com/c/CROSSWorldChurch"
                className="text-white transition-colors duration-300 hover:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ffffff"
                  viewBox="0 0 30 30"
                  width="30px"
                  height="30px"
                >
                  {" "}
                  <path d="M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/crossworldchurch/"
                className="text-white transition-colors duration-300 hover:text-white"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/CROSSWorldChurch"
                className="text-white transition-colors duration-300 hover:text-white"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
            </div>
            <p className="mt-4 text-sm text-white">
              Follow us on our social media.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
          <p className="text-sm text-white">
            © Copyright 2023 CROSSWorld Church. All rights reserved.
          </p>
          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            <li>
              <NavLink
                to="/faq"
                className="text-sm text-white transition-colors duration-300 hover:text-white"
              >
                F.A.Q
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/get-tickets"
                className="text-sm text-white transition-colors duration-300 hover:text-white"
              >
                Register
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/my-tickets"
                className="text-sm text-white transition-colors duration-300 hover:text-white"
              >
                My Tickets
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
