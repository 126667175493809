import React from "react";
import Guide, { PaymentGuide } from "../../components/Guide/Guide";
import PageBanner from "../../components/PageBanner/PageBanner";

export default function GuidePage() {
  return (
    <>
      <PageBanner />
      <Guide />
      <PaymentGuide />
    </>
  );
}
