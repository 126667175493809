import React from "react";
import { Outlet } from "react-router-dom";
import PageBanner from "../../components/PageBanner/PageBanner";

export default function MyTicketWrapper() {
  return (
    <>
      <PageBanner />
      <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12 lg:pb-2">
        <div className="flex flex-col mb-4 lg:justify-between lg:flex-row md:mb-4">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">My Tickets</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary-main scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 lg:text-sm lg:max-w-md"></p>
        </div>
      </div>
      <div className="pb-6 px-4 mb-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <Outlet />
      </div>
    </>
  );
}
