import { motion, Variants } from "framer-motion";
import Countdown from "../Countdown/Countdown";

export default function Banner() {
  const handleClick = () => {
    const targetElement = document.querySelector(".faq-section-container");

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{
        backgroundImage:
          "repeating-linear-gradient(45deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),repeating-linear-gradient(135deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),linear-gradient(90deg, hsl(256,7%,84%),hsl(256,7%,84%))",
      }}
    >
      <div className="relative overflow-hidden">
        <div
          className="bg-accent-main relative"
          style={{
            clipPath: "polygon(50% 100%, 100% 85%, 100% 0, 0 0, 0 85%)",
          }}
        >
          <div
            className="absolute object-cover w-full h-full top-[-12px] left-0"
            style={{
              clipPath: "polygon(50% 100%, 100% 85%, 100% 0, 0 0, 0 85%)",
            }}
          >
            <div
              style={{
                backgroundImage: `url(/images/header-bg.webp)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundAttachment: "fixed",
              }}
              className="absolute object-cover w-full h-full top-0 left-0"
              alt=""
            />

            <motion.img
              src="/images/cross.webp"
              className="absolute object-cover w-[50%] translate-x-1/2 top-[-30%] left-0 "
              alt=""
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              viewport={{ once: true }}
            />
          </div>
          <motion.div
            className="relative px-4 py-16 md:py-24 mx-auto lg:py-48 md:px-8 xl:px-4 sm:max-w-xl md:max-w-full"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <div className=" flex items-center justify-center">
              <img
                src="/images/header_text.webp"
                className="max-w-full w-full md:w-8/12 md:max-w-7xl "
                alt=""
              />
            </div>
          </motion.div>
        </div>
      </div>
      <div className="px-4 pb-8 pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-4 lg:pt-8 grid grid-cols-1 mt-4 lg:grid-cols-1 justify-center">
        <div>
          <p className="mb-2 text-xs font-semibold tracking-wide text-gray-600 uppercase text-center">
            Event Details
          </p>
          <div className="max-w-xl mx-auto lg:mx-  text-center lg:text-center">
            <div className="">
              <a
                href="/"
                aria-label="Article"
                className="inline-block max-w-lg font-sans text-6xl font-extrabold leading-none tracking-tight text-black transition-colors duration-200 sm:text-4xl"
              >
                October 27-28, 2023
                <p className="text-gray-800 font-medium text-xl">
                  CROSSWorld Center, L4468 Bulak St., Meycauayan City, Bulacan
                </p>
              </a>
            </div>
            {/* <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
          <div>
            <p className="text-gray-800 font-bold">Registration Period</p>
            <p>July 16, 2023 to October 15, 2023</p>
            <p>Php 1,000</p>
          </div> */}
            {/* <div>
                <p className="text-gray-800 font-bold">Early Bird Promo</p>
                <p>
                  <b>Extended until August 31, 2023</b>
                </p>
                <p>Php 750.00</p>
              </div> */}
            {/* </div> */}
          </div>
        </div>
        <div>
          <Countdown />
        </div>
        <div className="sm:text-center lg:col-span-2 flex justify-center">
          <span
            onClick={handleClick}
            aria-label=""
            className="cursor-pointer select-none text-center lg:text-start mt-4 lg:mt-12 inline-flex items-center font-semibold transition-colors duration-200 text-accent-main hover:text--accent-dark underline"
          >
            Go to Registration Details
          </span>
        </div>
      </div>
    </div>
  );
}
