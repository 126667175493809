import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { AuthProvider } from "./context/AppContext";
import { Routes, Route } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Homepage } from "./pages/Homepage";
import { MyTicket } from "./pages/MyTicket";
import { NewTicket } from "./pages/NewTicket";
import ProtectedPage from "./components/ProtectedPage/ProtectedPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyTicketWrapper from "./pages/MyTicketWrapper/MyTicketWrapper";
import ViewTicket from "./pages/ViewTicket/ViewTicket";
import FAQ from "./pages/FAQ/FAQ";
import GuidePage from "./pages/GuidePage/GuidePage";
import ViewTicketPDF from "./pages/ViewTicketPDF/ViewTicketPDF";
import NotFound from "./components/NotFound/NotFound";
import PageBanner from "./components/PageBanner/PageBanner";

function App() {
  return (
    <div
      className="App scroll-smooth"
      style={{
        backgroundImage:
          "repeating-linear-gradient(45deg, rgb(255, 255, 255) 0px, rgb(255, 255, 255) 10px, transparent 10px, transparent 11px), repeating-linear-gradient(135deg, rgb(255, 255, 255) 0px, rgb(255, 255, 255) 10px, transparent 10px, transparent 11px), linear-gradient(90deg, rgb(213, 211, 217), rgb(213, 211, 217))",
      }}
    >
      <AuthProvider>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage></Homepage>} />
          <Route
            element={
              <ProtectedPage>
                <MyTicketWrapper />
              </ProtectedPage>
            }
          >
            <Route path="/my-tickets" element={<MyTicket />} />
            <Route path="/my-tickets/:id" element={<ViewTicket />} />
            <Route path="/view-pdf/:id" element={<ViewTicketPDF />} />
          </Route>
          <Route
            path="/get-tickets"
            element={
              <ProtectedPage
                title="Login to order ticket"
                subtitle="Login using google account to order ticket. Make sure you log-in via trusted app such as Google Chrome / Firefox / Safari to avoid any login issues."
              >
                <NewTicket />
              </ProtectedPage>
            }
          />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/guide" element={<GuidePage />} />
          <Route
            path="*"
            element={
              <>
                <PageBanner />
                <NotFound />
              </>
            }
          />
          <Route />
        </Routes>
        <Footer />
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
