import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserAuth } from "../../context/AppContext";
import QRCode from "react-qr-code";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { storage } from "../../firebase/";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { v4 as uuid } from "uuid";
import { PaymentGuide } from "../../components/Guide/Guide";
import NotFound from "../../components/NotFound/NotFound";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const getMessageByStatusCode = (code) => {
  if (code === "101") {
    return {
      title: `Your uploaded receipt is under validation`,
      subtitle: (
        <>
          Thank you for your payment! We kindly ask for your patience as it may
          take three to five working days to confirm. Once approved, you can
          access and download your conference ticket(s) on the website.
          <br /> <br />
          For tickets registered from Sunday to Tuesday, you can check if
          they're validated on Thursday. For tickets registered from Wednesday
          to Saturday, you can check on Monday.
        </>
      ),
    };
  } else if (code === "102") {
    return {
      title: `Ticket succesfully validated`,
      subtitle: `You can now able to download and view the tickets via PDF. You can screenshot the QR code below to present during the conference or you can print the tickets via PDF.`,
    };
  } else if (code === "500") {
    return {
      title: `Ticket automatically voided`,
      subtitle: `We apologize for the inconvenience, but we must inform you that your ticket has been automatically voided because you did not upload the receipt within one hour of registration. We kindly request you to register again as soon as possible. Thank you for your understanding.`,
    };
  }
  return null;
};

export default function ViewTicket() {
  const { id } = useParams();
  const {
    accountTickets,
    updateTicketTransaction,
    showToast,
    showWarnToast,
    voidTicket,
  } = UserAuth();
  const [progresspercent, setProgresspercent] = useState(0);
  const [submitText, setSubmitText] = useState("Upload Image and Submit");
  const [uploadStatus, setUploadStatus] = useState(
    "Uploading Image, Please wait..."
  );
  const [countdown, setCountdown] = useState({
    minutes: 0,
    seconds: 0,
    totalRemaining: 0,
  });

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openPaymentGuideline, setOpenPaymentGuideline] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const descriptionElementRef = React.useRef(null);
  const tickets =
    (accountTickets !== "init" &&
      accountTickets.find((ticket) => ticket.ticket_id === id)) ||
    [];

  const [files, setFiles] = useState([]);
  const [remarks, setRemarks] = useState("");
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleSubmitForm = async (downloadURL) => {
    setUploadStatus("Submitting Form Data, please wait...");
    setSubmitText("Submitting...");
    await updateTicketTransaction(tickets, downloadURL, remarks);
    showToast("Successfully uploaded and submitted.");
    setProgresspercent(0);
  };

  const handleUploadFile = () => {
    const file = files[0];
    if (!file || isUploading) return;
    setIsUploading(true);
    setSubmitText("Uploading...");
    const fileExtension = "." + file.name.split(".").pop();

    const storageRef = ref(
      storage,
      `files/${
        tickets.ticket_id + uuid().substring(0, 6).toUpperCase() + fileExtension
      }`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
        setProgresspercent(0);
        setSubmitText("Upload Image and Submit");
        setUploadStatus("Uploading Image, Please wait...");
      },
      () => {
        setIsUploading(false);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          handleSubmitForm(downloadURL);
        });
      }
    );
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="Preview"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <div
          className="shadow-sm bg-white rounded-full"
          style={{
            position: "absolute",
            right: "0",
            margin: "8px",
            cursor: "pointer",
          }}
        >
          <Tooltip title="Click to remove the image">
            <CancelIcon onClick={() => setFiles([])} />
          </Tooltip>
        </div>
      </div>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <>
        {errors.map((e) => (
          <Alert
            style={{ marginBottom: "6px" }}
            severity="warning"
            key={e.code}
          >
            {" "}
            <span className="font-semibold font-sans">
              {file.path} - {file.size} bytes
            </span>
            <br />
            <span className="font-sans">{e.message}</span>
          </Alert>
        ))}
      </>
    );
  });
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  React.useEffect(() => {
    if (openUploadDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openUploadDialog]);

  const { title, subtitle } = getMessageByStatusCode(tickets?.status_code) || {
    title: `Upload your transaction receipt`,
    subtitle: null,
  };

  useEffect(() => {
    if (!tickets.dateAdded) return;
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const targetTime = tickets.dateAdded.toMillis() + 60 * 60 * 1000; // 1 hour later

      const timeDifference = targetTime - currentTime;
      const minutesLeft = Math.floor(timeDifference / (1000 * 60));
      const secondsLeft = Math.floor((timeDifference / 1000) % 60);

      if (timeDifference <= 0 && tickets.status_code === "100") {
        voidTicket(tickets);
      }

      setCountdown({
        minutes: minutesLeft,
        seconds: secondsLeft,
        totalRemaining: timeDifference,
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [tickets]);

  if (tickets.length === 0) {
    return <NotFound />;
  }
  const isVoided = tickets.status_code === "500";

  return (
    <>
      <NavLink
        to={"/my-tickets"}
        aria-label=""
        className="inline-flex mb-8 items-center text-sm font-semibold transition-colors duration-200 text-primary-main hover:text-blue-800"
      >
        <ArrowBackIcon style={{ marginRight: "12px" }} /> Back to order ticket
        list
      </NavLink>
      <br />

      <div
        className={`grid gap-2 grid-cols-1 sm:grid-cols-2 ${
          tickets.status_code === "100" ? "xl:grid-cols-5" : "xl:grid-cols-4"
        }`}
      >
        <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
          <div className="relative p-5 bg-white rounded-sm">
            <div>
              <span className="text-xs text-gray-700 uppercase ">
                Order Ticket ID
              </span>
            </div>
            <span className="text-lg font-semibold">{tickets.ticket_id}</span>
          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
          <div className="relative p-5 bg-white rounded-sm">
            <div>
              <span className="text-xs text-gray-700 uppercase ">Status</span>
            </div>
            <span className="text-lg font-semibold">{tickets.status}</span>
          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
          <div className="relative p-5 bg-white rounded-sm">
            <div>
              <span className="text-xs text-gray-700 uppercase ">
                Total No. of Tickets
              </span>
            </div>
            <span className="text-lg font-semibold">
              {tickets.no_of_tickets}
            </span>
          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
          <div className="relative p-5 bg-white rounded-sm">
            <div>
              <span className="text-xs text-gray-700 uppercase ">
                Total Price
              </span>
            </div>
            <span className="text-lg font-semibold">
              Php{" "}
              {(tickets.no_of_tickets * tickets.baseAmount).toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </span>
          </div>
        </div>
        {tickets.status_code === "100" && (
          <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
            <div className="relative p-5 bg-white rounded-sm">
              <div>
                <span className="text-xs text-gray-700 uppercase ">
                  Ticket Reserved until:
                </span>
              </div>
              <span className="text-lg font-semibold">
                {countdown.totalRemaining > 0
                  ? countdown.minutes.toString().padStart(2, "0")
                  : "--"}
                :
                {countdown.totalRemaining > 0
                  ? countdown.seconds.toString().padStart(2, "0")
                  : "--"}
              </span>
            </div>
          </div>
        )}
      </div>
      <header className="bg-gray-50 mb-8">
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-12 sm:px-6 lg:px-8">
          <div className="sm:justify-between sm:items-center md:flex gap-12">
            <div className="text-center sm:text-left">
              <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                {title}
              </h1>
              <p className="mt-1.5 text-sm text-gray-700 max-w-xl">
                {subtitle || (
                  <>
                    We kindly remind you to{" "}
                    <b>
                      upload the transaction receipt within one hour of
                      registration to confirm your ticket order.{" "}
                    </b>
                    Simply click the upload button to attach the screenshot.
                    <br />
                    <br />
                    If you need guidance on how to make the payment, please
                    click on "Payment Guidelines". It's important to note that
                    if no receipt is uploaded within one hour, the ticket will
                    be automatically voided. Thank you for your cooperation!
                  </>
                )}
                {tickets?.status_code === "102" && (
                  <p>
                    <br />
                    <b>Note:</b> Bring and present your conference ticket to the
                    registration booth on day 1 and day 2 of the conference. A
                    “No conference ticket or QR code, No Entry” policy will be
                    observed.
                  </p>
                )}
              </p>
            </div>
            {!isVoided && (
              <div className="flex flex-col gap-4 mt-4 sm:flex-row sm:mt-0 sm:items-center">
                <button
                  onClick={() => setOpenPaymentGuideline(true)}
                  className="inline-flex items-center justify-center px-5 py-3 text-gray-500 transition bg-white border border-gray-200 rounded-lg hover:text-gray-700 focus:outline-none focus:ring"
                  type="button"
                >
                  <span className="text-sm font-medium">
                    {" "}
                    Payment Guideline{" "}
                  </span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 ml-1.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </button>

                <button
                  className="block px-5 py-3 text-sm font-medium text-white transition bg-blue-accent-700 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring"
                  type="button"
                  onClick={() => setOpenUploadDialog(true)}
                >
                  {tickets?.transaction_img_src ? "View Submitted " : "Upload"}{" "}
                  Receipt
                </button>
                {/* <button
                  className="block px-5 py-3 text-sm font-medium text-white transition bg-blue-accent-700 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring"
                  type="button"
                  onClick={() => voidTicket(tickets)}
                >
                  Force Void
                </button> */}
              </div>
            )}
          </div>
        </div>
      </header>

      <header className="">
        <div className="max-w-screen-xl mx-auto sm:pb-8">
          <div className="sm:justify-between sm:items-center md:flex">
            <div className="text-center sm:text-left">
              <div className="flex flex-col lg:justify-between lg:flex-row">
                <h2 className="max-w-lg font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none group">
                  <span className="inline-block">
                    Ticket Holder Information
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
        <div className="mb-2 border-dashed hover:border-gray-400 border-2 relative p-5 bg-white rounded-sm  w-full">
          <div className="pr-4 grid md:flex-row md:flex-wrap  lg:flex-nowrap gap-8">
            {/* {tickets?.ticket_id && tickets?.is_paid && (
              <div className=" max-w-lg mt-0 font-sans text-md font-bold tracking-tight text-gray-900 sm:leading-none group">
                <QRCode size={150} value={tickets.ticket_id} />
              </div>
            )} */}
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                <div className="grid">
                  <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                    full name
                  </p>
                  <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                    {tickets?.full_name || "-"}
                  </p>
                </div>
                <div className="grid">
                  <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                    Contact Number
                  </p>
                  <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                    {tickets?.contact_number || "-"}
                  </p>
                </div>
                <div className="grid">
                  <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                    Church Name
                  </p>
                  <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                    {tickets?.church_name || "-"}
                    {tickets?.others_church_name
                      ? ", " + tickets?.others_church_name
                      : ""}
                  </p>
                </div>
                <div className="grid">
                  <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                    Location
                  </p>
                  <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                    {tickets?.city || "-"}
                    {tickets?.province ? ", " + tickets?.province : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {tickets?.is_paid && (
        <>
          <header className="mt-12">
            <div className="max-w-screen-xl mx-auto sm:pb-8">
              <div className="sm:justify-between sm:items-center md:flex">
                <div className="text-center sm:text-left">
                  <div className="flex flex-col lg:justify-between lg:flex-row">
                    <h2 className="max-w-lg font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none group">
                      <span className="inline-block">Ticket List</span>
                    </h2>
                  </div>
                </div>

                <div className="flex flex-col gap-4 mt-4 sm:flex-row sm:mt-0 sm:items-center">
                  <NavLink
                    to={"/view-pdf/" + tickets.ticket_id}
                    className="block px-5 py-3 text-sm font-medium text-primary-main transition rounded-lg border-2 border-transparent hover:border-2 hover:border-primary-main focus:outline-none focus:ring"
                  >
                    View/Download Tickets as PDF
                  </NavLink>
                </div>
              </div>
            </div>
          </header>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8">
            {Array.from({ length: tickets.no_of_tickets }, (_, index) => (
              <div className="mb-2 border-dashed hover:border-gray-400 border-2 relative p-5 bg-white rounded-sm  w-full">
                <div className="pr-4 grid md:flex-row md:flex-wrap lg:flex-nowrap gap-8">
                  <>
                    <div className=" max-w-lg grid justify-center mt-0 font-sans text-md font-bold tracking-tight text-gray-900 sm:leading-none group">
                      <QRCode
                        size={150}
                        value={
                          tickets.ticket_id +
                          "-" +
                          (index + 1).toString().padStart(3, "000")
                        }
                      />
                    </div>
                    <div>
                      <div className="grid">
                        <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md text-center">
                          Ticket ID
                        </p>
                        <p className="font-sans font-semibold text-gray-900 lg:text-xl lg:max-w-md text-center">
                          {tickets.ticket_id +
                            "-" +
                            (index + 1).toString().padStart(3, "000")}
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <Dialog
        open={openUploadDialog}
        onClose={() => progresspercent !== 0 && setOpenUploadDialog(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
            <span className="inline-block mb-1 sm:mb-4">
              {tickets?.transaction_img_src ? "View" : "Upload"} Transaction
              Receipt
            </span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary-main scale-x-30 group-hover:scale-x-100" />
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            component={"div"}
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {!tickets?.transaction_img_src && (
              <>
                <Alert severity="info">
                  <span className="font-sans">
                    <b>Note:</b> Make sure that the uploaded image is clear and
                    the details matched with your order. If you have any
                    question, contact us at idmccrossworld@gmail.com
                  </span>
                </Alert>
                <section className="container mt-4">
                  {fileRejectionItems}
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="grid items-center justify-center gap-4 p-12 bg-gray-200 rounded-md border border-dashed border-gray-500">
                      <CloudDownloadIcon
                        fontSize={"large"}
                        htmlColor="#323192"
                      />
                      <span className="font-sans text-xl">
                        Drag and drop image file here, or click to select image
                        file.
                        <br />
                        <br /> Only 1 image is accepted
                      </span>
                    </div>
                  </div>
                  <p className="mb-1 text-xs mt-4 font-sans md:text-start text-black font-semibold tracking-wide uppercase">
                    Preview:
                  </p>
                  <aside style={thumbsContainer} className="mb-8">
                    {thumbs}
                  </aside>
                  <div className="font-sans">
                    <TextField
                      id="outlined-multiline-static"
                      label="Additional Information / Remarks"
                      fullWidth
                      multiline
                      value={remarks}
                      onChange={(ev) => setRemarks(ev.currentTarget.value)}
                      rows={4}
                    />
                  </div>
                </section>
              </>
            )}
            {tickets?.transaction_img_src && (
              <>
                <img
                  src={tickets?.transaction_img_src}
                  className="w-full"
                  alt="uploaded transaction"
                />
                {tickets?.user_message && (
                  <>
                    <p className="mb-1 text-xs mt-4 font-sans md:text-start text-black font-semibold tracking-wide uppercase">
                      Your Provided Additional Details / Remarks:
                    </p>
                    <p className="mb-1 text-md mt-4 font-sans md:text-start text-black font-semibold tracking-wide">
                      {tickets?.user_message}
                    </p>
                  </>
                )}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <span
            tabIndex={0}
            className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none`}
            onClick={() => setOpenUploadDialog(false)}
          >
            Back
          </span>
          {!tickets?.transaction_img_src && (
            <span
              tabIndex={0}
              className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md shadow-md border border-primary-main text-white bg-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none hover:shadow-xl`}
              onClick={() => {
                if (files && files.length > 0) {
                  setProgresspercent(1);
                  handleUploadFile();
                } else {
                  showWarnToast("Please select image.");
                }
              }}
            >
              {/* {!isSubmitting ? "Submit" : "Submitting..."} */}
              {submitText}
            </span>
          )}
        </DialogActions>

        {!!progresspercent && (
          <div
            style={{
              display: "grid",
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "white",
              zIndex: "99",
              opacity: "0.9",
            }}
          >
            {" "}
            <div className="block py-4 m-auto">
              <span className="font-sans font-semibold uppercase">
                {uploadStatus}
              </span>
              <div className="w-full h-4 bg-gray-400 rounded-full mt-3">
                <div
                  style={{ width: progresspercent + "%" }}
                  className="h-full text-center text-xs text-white bg-green-500 rounded-full"
                >
                  {progresspercent}%
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        open={openPaymentGuideline}
        onClose={() => setOpenPaymentGuideline(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
            <span className="inline-block mb-1 sm:mb-4">Payment Guideline</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary-main scale-x-30 group-hover:scale-x-100" />
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            component={"div"}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <PaymentGuide hideImage />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <span
            tabIndex={0}
            className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none`}
            onClick={() => setOpenPaymentGuideline(false)}
          >
            Back
          </span>
        </DialogActions>

        {!!progresspercent && (
          <div
            style={{
              display: "grid",
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "white",
              zIndex: "99",
              opacity: "0.9",
            }}
          >
            {" "}
            <div className="block py-4 m-auto">
              <span className="font-sans font-semibold uppercase">
                {uploadStatus}
              </span>
              <div className="w-full h-4 bg-gray-400 rounded-full mt-3">
                <div
                  style={{ width: progresspercent + "%" }}
                  className="h-full text-center text-xs text-white bg-green-500 rounded-full"
                >
                  {progresspercent}%
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
}
