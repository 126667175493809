import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { cityList } from "./cityList";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import { UserAuth } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const initVal = {
  full_name: "",
  contact_number: "",
  church_name: "",
  others_church_name: "",
  city: "",
  province: "",
  type: "",
  uuid: uuid(),
  no_of_tickets: 1,
};

const priceType = {
  "adult-ministers": 700,
  students: 500,
  gatekeeper: 350,
  missions: 500,
};

const initialValues = {
  tickets: [initVal],
};

export default function RegistrationForm() {
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openReviewSummary, setOpenReviewSummary] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [selectedTicketIndex, setSelectedTicketIndex] = useState(0);
  const [selectedType, setSelectedType] = useState("");
  const descriptionElementRef = React.useRef(null);
  const navigate = useNavigate();
  const { addTicket, showWarnToast, showToast, isEarlyBird, settings, user } =
    UserAuth();

  const isSuperUser =
    user !== "init"
      ? user.uid === "WFIeYwZvbTNCT4Ret6eyZ3gMPVB2" ||
        user.uid === "1r2Tq75rPmg7PO6plkxglgEMBdD2" ||
        user.uid === "IPCa2JjOx0VACfD9oJndQmCkqf13"
      : false;

  React.useEffect(() => {
    if (openReviewSummary) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openReviewSummary]);

  const validationSchema = yup.object().shape({
    tickets: yup.array().of(
      yup.object().shape({
        full_name: yup.string().required("Full Name is required"),
        church_name: yup.string().required("Church Name is required"),
        contact_number: yup.string().required("Contact Number is required"),
        city: yup.string().required("City is required"),
        province: yup.string().required("Province is required"),
        no_of_tickets: yup
          .string()
          .test("no_of_tickets", "Please enter valid number", (value) => {
            return value > 0 && /^[0-9]*$/.test(value);
          })
          .required("Please enter valid number of tickets"),
      })
    ),
  });
  // const initialIsValid = validationSchema.isValidSync(initialValues)

  const price = priceType[selectedType] || (isEarlyBird ? 750 : 1000);

  if (!isSuperUser)
    return (
      <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12">
        <div className="bg-white">
          <div className="lg:flex lg:items-center lg:justify-between w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
            <h2 className="text-3xl font-extrabold text-black sm:text-4xl">
              <span className="block">Registration is now closed</span>
              <span className="block text-indigo-500 text-xl font-medium pt-4">
                For inquiries, kindly contact our facebook page{": "}
                <a
                  href="https://www.facebook.com/CROSSWorldChurch"
                  target="_blank"
                >
                  <u>CROSSWorld Church</u>
                </a>
              </span>
            </h2>
            <div className="lg:mt-0 lg:flex-shrink-0">
              <div className=" inline-flex rounded-md shadow"></div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      isValid={false}
      onSubmit={async (values) => {
        const ticket_id = await addTicket(values, price, selectedType);
        setOpenReviewSummary(false);
        showToast("Ticket successfully created");
        navigate("/my-tickets/" + ticket_id);
      }}
    >
      {({
        isSubmitting,
        validateForm,
        errors,
        handleChange,
        resetForm,
        submitForm,
        handleBlur,
        setFieldValue,
        values,
      }) => (
        <>
          <Form>
            <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12">
              <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
                <div className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
                  <span className="inline-block mb-1 sm:mb-4">Register</span>
                  <div className="h-1 ml-auto duration-300 origin-left transform bg-primary-main scale-x-30 group-hover:scale-x-100" />
                </div>
                <p className="text-gray-700 lg:text-sm lg:max-w-md">
                  Notice: After you submit the form, you'll be directed to the
                  payment page.{" "}
                  <b>
                    Don't forget to upload your receipt within one hour of
                    submission to keep your ticket valid.
                  </b>{" "}
                  If you miss the deadline, your ticket will unfortunately
                  become void.
                </p>
              </div>
              <Box
                display="grid"
                className="grid grid-cols-1 xl:grid-cols-2 bg-white rounded md:justify-center lg:flex-row flex-wrap"
              >
                <TransitionGroup component={null}>
                  {values.tickets.length > 0 &&
                    values.tickets.map((ticket, index) => (
                      <Grow
                        key={ticket.uuid}
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{ timeout: 500 }}
                      >
                        <div className="flex flex-col justify-between p-5 sm:p-10 border border-gray-300">
                          <div>
                            <div className="flex justify-between align-start">
                              <div>
                                <Tooltip
                                  title="Early bird promo is only applicable for payment submitted before August 31, 2023."
                                  placement="top"
                                >
                                  <p className="mb-2 text-xs font-semibold tracking-wide uppercase">
                                    {priceType[selectedType] && (
                                      <>
                                        <span
                                          style={{
                                            textDecoration: "line-through",
                                            opacity: "0.5",
                                          }}
                                        >
                                          Php 1000.00
                                        </span>{" "}
                                        Php {priceType[ticket.type]}.00
                                      </>
                                    )}
                                    {!priceType[selectedType] &&
                                      isEarlyBird && (
                                        <>
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                              opacity: "0.5",
                                            }}
                                          >
                                            Php 1000.00
                                          </span>{" "}
                                          Php 750.00
                                        </>
                                      )}
                                    {!priceType[selectedType] &&
                                      !isEarlyBird && <span>Php 1000.00 </span>}
                                  </p>
                                </Tooltip>
                                <h5 className="max-w-md mb-6 text-3xl font-extrabold leading-none sm:text-4xl">
                                  Ticket Form
                                </h5>
                              </div>
                              {index >= 1 && (
                                <div>
                                  <Tooltip title="Click to remove this ticket">
                                    <HighlightOffIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectedTicketIndex(index);
                                        setOpenDeleteConfirmDialog(true);
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </div>

                            <div>
                              <Box display="grid" gap="20px">
                                <TextField
                                  id="outlined-basic"
                                  label="Full Name"
                                  error={
                                    !!(
                                      errors?.tickets &&
                                      errors.tickets[index]?.full_name
                                    )
                                  }
                                  helperText={
                                    errors?.tickets &&
                                    errors.tickets[index]?.full_name
                                  }
                                  value={ticket?.full_name}
                                  onChange={handleChange}
                                  name={`tickets.${index}.full_name`}
                                  variant="outlined"
                                  fullWidth
                                />
                                <Grid container wrap="nowrap" gap="20px">
                                  <TextField
                                    id="outlined-basic"
                                    label="Contact Number"
                                    type="tel"
                                    error={
                                      !!(
                                        errors?.tickets &&
                                        errors.tickets[index]?.contact_number
                                      )
                                    }
                                    helperText={
                                      errors?.tickets &&
                                      errors.tickets[index]?.contact_number
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          (+63)
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={ticket?.contact_number}
                                    onChange={handleChange}
                                    name={`tickets.${index}.contact_number`}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </Grid>

                                <FormControl fullWidth>
                                  <InputLabel
                                    error={
                                      !!(
                                        errors?.tickets &&
                                        errors.tickets[index]?.church_name
                                      )
                                    }
                                    id={"select-church-name-label-" + index}
                                  >
                                    Church Name
                                  </InputLabel>
                                  <Select
                                    labelId={
                                      "select-church-name-label-" + index
                                    }
                                    id={"select-church-name-select-" + index}
                                    label="Church Name"
                                    name={`tickets.${index}.church_name`}
                                    value={ticket ? ticket.church_name : ""}
                                    onChange={handleChange}
                                    error={
                                      !!(
                                        errors?.tickets &&
                                        errors.tickets[index]?.church_name
                                      )
                                    }
                                  >
                                    <MenuItem disabled value={""}>
                                      CROSSWorld Churces
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Central">
                                      CROSSWorld Central
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Kalookan">
                                      CROSSWorld Kalookan
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Obando">
                                      CROSSWorld Obando
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Valenzuela">
                                      CROSSWorld Valenzuela
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld San Rafael">
                                      CROSSWorld San Rafael
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Jesus Castle">
                                      CROSSWorld Jesus Castle
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Bignay">
                                      CROSSWorld Bignay
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Aliaga">
                                      CROSSWorld Aliaga
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Sibug">
                                      CROSSWorld Sibug
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Sta. Barbara">
                                      CROSSWorld Sta. Barbara
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld San Carlos">
                                      CROSSWorld San Carlos
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Palawan">
                                      CROSSWorld Palawan
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Davao">
                                      CROSSWorld Davao
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Bacolod">
                                      CROSSWorld Bacolod
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Peñafrancia">
                                      CROSSWorld Peñafrancia
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Burias">
                                      CROSSWorld Burias
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld UK">
                                      CROSSWorld UK
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Indonesia">
                                      CROSSWorld Indonesia
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Ubihan">
                                      CROSSWorld Ubihan
                                    </MenuItem>
                                    <MenuItem value="CROSSWorld Tiaong">
                                      CROSSWorld Tiaong (Outreach)
                                    </MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                  </Select>
                                </FormControl>

                                {isSuperUser && (
                                  <FormControl fullWidth>
                                    <InputLabel
                                      error={
                                        !!(
                                          errors?.tickets &&
                                          errors.tickets[index]?.type
                                        )
                                      }
                                      id={"select-type-label-" + index}
                                    >
                                      Ticket Type (For Admin)
                                    </InputLabel>

                                    <Select
                                      labelId={"select-type-label-" + index}
                                      id={"select-type-select-" + index}
                                      label="Registration Type"
                                      name={`tickets.${index}.type`}
                                      value={ticket ? ticket.type : ""}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setSelectedType(e.target.value);
                                      }}
                                      error={
                                        !!(
                                          errors?.tickets &&
                                          errors.tickets[index]?.type
                                        )
                                      }
                                    >
                                      <MenuItem disabled value={""}>
                                        Select Type
                                      </MenuItem>
                                      <MenuItem value="normal-auto-approved">
                                        Normal Type - Auto Approve
                                      </MenuItem>
                                      <MenuItem value="normal-auto-approved-and-check-in_day_1">
                                        Normal Type - Auto Approve and check in
                                        DAY 1
                                      </MenuItem>
                                      <MenuItem value="normal-auto-approved-and-check-in_day_2">
                                        Normal Type - Auto Approve and check in
                                        DAY 2
                                      </MenuItem>
                                      <MenuItem value="normal">
                                        Normal Type (1000p or 750p if early
                                        bird)
                                      </MenuItem>
                                      <MenuItem value="adult-ministers">
                                        Adults - Ministers (700p)
                                      </MenuItem>
                                      <MenuItem value="students">
                                        Students - Ministers (500p)
                                      </MenuItem>
                                      <MenuItem value="gatekeeper">
                                        Gatekeeper - Ministers (350p)
                                      </MenuItem>
                                      <MenuItem value="missions">
                                        Missions (500 - but need to update soon)
                                      </MenuItem>
                                    </Select>

                                    {errors?.tickets &&
                                      errors.tickets[index]?.church_name && (
                                        <FormHelperText
                                          htmlFor="form-selector"
                                          error={
                                            !!(
                                              errors?.tickets &&
                                              errors.tickets[index]?.church_name
                                            )
                                          }
                                        >
                                          {errors?.tickets &&
                                            errors.tickets[index]?.church_name}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                )}
                                {values.tickets[index].church_name ===
                                  "Others" && (
                                  <TextField
                                    disabled={
                                      values.tickets[index].church_name !==
                                      "Others"
                                    }
                                    value={ticket?.others_church_name}
                                    onChange={handleChange}
                                    id="outlined-basic"
                                    label="Other Church, Please Specify"
                                    variant="outlined"
                                    name={`tickets.${index}.others_church_name`}
                                    fullWidth
                                  />
                                )}
                                <Grid container wrap="nowrap" gap="20px">
                                  <TextField
                                    value={ticket?.city}
                                    onChange={handleChange}
                                    id="outlined-basic"
                                    error={
                                      !!(
                                        errors?.tickets &&
                                        errors.tickets[index]?.city
                                      )
                                    }
                                    helperText={
                                      errors?.tickets &&
                                      errors.tickets[index]?.city
                                    }
                                    label="City"
                                    variant="outlined"
                                    name={`tickets.${index}.city`}
                                    fullWidth
                                  />
                                  <FormControl fullWidth>
                                    <InputLabel
                                      error={
                                        !!(
                                          errors?.tickets &&
                                          errors.tickets[index]?.province
                                        )
                                      }
                                      id={"select-province-label-" + index}
                                    >
                                      Province
                                    </InputLabel>
                                    <Select
                                      labelId={"select-province-label-" + index}
                                      id="select-province-select"
                                      label="Province"
                                      name={`tickets.${index}.province`}
                                      value={ticket ? ticket.province : ""}
                                      onChange={handleChange}
                                      error={
                                        !!(
                                          errors?.tickets &&
                                          errors.tickets[index]?.province
                                        )
                                      }
                                    >
                                      <MenuItem disabled value={""}>
                                        Select Province
                                      </MenuItem>
                                      {cityList.map((city, i) => (
                                        <MenuItem key={i} value={city}>
                                          {city}
                                        </MenuItem>
                                      ))}
                                    </Select>

                                    {errors?.tickets &&
                                      errors.tickets[index]?.province && (
                                        <FormHelperText
                                          htmlFor="form-selector"
                                          error={
                                            !!(
                                              errors?.tickets &&
                                              errors.tickets[index]?.province
                                            )
                                          }
                                        >
                                          {errors?.tickets &&
                                            errors.tickets[index]?.province}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid container wrap="nowrap" gap="20px">
                                  <div className="grid mt-2 w-full">
                                    <label
                                      htmlFor="Quantity"
                                      className="sr-only"
                                    >
                                      {" "}
                                      Quantity{" "}
                                    </label>

                                    <div
                                      className="flex flex-row w-full rounded-lg relative rounded-2xl"
                                      style={{}}
                                    >
                                      <button
                                        type="button"
                                        className="bg-gray-200 border border-1 rounded-sm h-full  text-textColor-dark px-8 text-2xl transition hover:opacity-75 "
                                        onClick={() => {
                                          setFieldValue(
                                            `tickets.${index}.no_of_tickets`,
                                            ~~ticket?.no_of_tickets <= 1
                                              ? 1
                                              : ~~ticket?.no_of_tickets - 1
                                          );
                                        }}
                                      >
                                        -
                                      </button>

                                      <TextField
                                        value={ticket?.no_of_tickets}
                                        onChange={(e) => {
                                          const val = e.target.value;
                                          if (val === "")
                                            return setFieldValue(
                                              `tickets.${index}.no_of_tickets`,
                                              ""
                                            );
                                          if (val > 0 && val <= 100) {
                                            setFieldValue(
                                              `tickets.${index}.no_of_tickets`,
                                              val
                                            );
                                          } else {
                                            setFieldValue(
                                              `tickets.${index}.no_of_tickets`,
                                              val > 100 ? 100 : 1
                                            );
                                          }
                                        }}
                                        inputMode="numeric"
                                        type="number"
                                        id="outlined-basic"
                                        fullWidth
                                        error={
                                          !!(
                                            errors?.tickets &&
                                            errors.tickets[index]?.no_of_tickets
                                          )
                                        }
                                        helperText={
                                          errors?.tickets &&
                                          errors.tickets[index]?.no_of_tickets
                                        }
                                        label="No. of Tickets"
                                        variant="outlined"
                                        name={`tickets.${index}.no_of_tickets`}
                                      />

                                      <button
                                        type="button"
                                        className="bg-gray-200 border border-1 rounded-sm h-full hover:bg-gray-300 text-textColor-dark px-8 text-2xl transition hover:opacity-75 "
                                        onClick={() => {
                                          setFieldValue(
                                            `tickets.${index}.no_of_tickets`,
                                            ~~ticket?.no_of_tickets > 100
                                              ? 100
                                              : ~~ticket?.no_of_tickets + 1
                                          );
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </Grid>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </Grow>
                    ))}

                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{ timeout: 500 }}
                  >
                    <div className="flex flex-col justify-between p-5 sm:p-10 border border-gray-300 bg-gray-300">
                      <div>
                        <p className="mb-2 text-xs font-semibold tracking-wide uppercase"></p>
                        <h5 className="max-w-md mb-6 text-3xl font-extrabold leading-none sm:text-4xl mt-6">
                          Ticket Summary
                        </h5>
                        <p className="mb-6 text-base text-gray-700 md:text-lg sm:mb-8">
                          <div className="relative block  mt-4 p-px overflow-hidden transition duration-300 transform">
                            {values?.tickets?.map((tix, index) => (
                              <div
                                key={index}
                                className="mb-2 border-dashed hover:border-gray-400 rounded border-2 relative p-5 bg-white rounded-sm  w-full"
                              >
                                <div className="pr-4">
                                  <div className="max-w-lg mt-0 font-sans text-md font-bold tracking-tight text-gray-900 sm:leading-none group">
                                    <span className="inline-block mb-1 sm:mb-2">
                                      Ticket Information
                                    </span>
                                    <div className="h-0.5 mb-2 ml-auto duration-300 origin-left transform bg-primary-main scale-x-30 group-hover:scale-x-100" />
                                  </div>
                                  <div className="grid grid-cols-1 sm:grid-cols-2">
                                    <div className="grid mt-2">
                                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                                        full name
                                      </p>
                                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                                        {tix?.full_name || "-"}
                                      </p>
                                    </div>
                                    <div className="grid mt-2">
                                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                                        Contact
                                      </p>
                                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                                        {tix?.contact_number || "-"}
                                      </p>
                                    </div>
                                    <div className="grid mt-2">
                                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                                        Church Name
                                      </p>
                                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                                        {tix?.church_name || "-"}
                                        {tix?.others_church_name
                                          ? ", " + tix?.others_church_name
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="grid mt-2">
                                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                                        Location
                                      </p>
                                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                                        {tix?.city || "-"}
                                        {tix?.province
                                          ? ", " + tix?.province
                                          : ""}
                                      </p>
                                    </div>

                                    <div className="grid mt-2">
                                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                                        No of tickets
                                      </p>
                                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                                        {tix?.no_of_tickets || "-"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </p>
                      </div>
                      <div className="flex items-center">
                        <div>
                          <p className="mb-2 text-xs text-end font-semibold tracking-wide uppercase">
                            Total Amount to pay:
                          </p>
                          <h5 className="text-end text-3xl font-extrabold my-0 leading-none sm:text-2xl">
                            {isEarlyBird && (
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  opacity: "0.5",
                                }}
                              >
                                Php {values?.tickets[0]?.no_of_tickets * 1000}{" "}
                              </span>
                            )}
                            Php{" "}
                            {(
                              values?.tickets[0]?.no_of_tickets * price
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </h5>
                          {isEarlyBird && (
                            <p className="text-sm text-gray-700 text-end">
                              Early bird promo is only applicable for payment
                              submitted before August 31, 2023.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grow>
                </TransitionGroup>
              </Box>

              <div className="grid justify-between items-center mt-10 grid-cols-1 md:grid-cols-2">
                <div>
                  {/* {isEarlyBird &&
                    settings.count >= 0 &&
                    settings.count < 200 && (
                      <p>
                        <span className="text-end md:text-start font-sans text-black text-2xl font-bold my-0 leading-none sm:text-xl">
                          {200 - settings.count} slots left for early bird promo
                          <br />
                          <span className="text-sm">(updated realtime)</span>
                        </span>
                      </p>
                    )} */}
                </div>
                <div className="flex justify-end items-end gap-5 mt-8 md:mt-0">
                  <span
                    tabIndex={0}
                    role="button"
                    onClick={() => setShowResetDialog(true)}
                    className="inline-flex items-center justify-center  text-center h-12 px-6 font-medium tracking-wide transition duration-200 rounded shadow-md border border-primary-main hover:border-blue-accent-700 text-primary-main focus:shadow-outline focus:outline-none hover:shadow-xl"
                  >
                    Clear all fields
                  </span>
                  <span
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      validateForm().then(() => {
                        const tix = values?.tickets || [];
                        const validFormCount = tix.reduce(
                          (
                            prev,
                            {
                              full_name,
                              contact_number,
                              church_name,
                              city,
                              province,
                            }
                          ) =>
                            (prev +=
                              (full_name ? 1 : 0) +
                              (contact_number ? 1 : 0) +
                              (church_name ? 1 : 0) +
                              (province ? 1 : 0) +
                              (city ? 1 : 0)),
                          0
                        );
                        if (
                          validFormCount &&
                          validFormCount >= tix.length * 5
                        ) {
                          setOpenReviewSummary(true);
                        } else {
                          showWarnToast("Some fields are required.");
                        }
                      });
                    }}
                    className={`bg-primary-main inline-flex items-center py-4 text-center md:py-0  justify-center h-12 px-6 font-medium tracking-wide transition duration-200 rounded shadow-md border border-primary-main hover:border-blue-accent-700 text-white focus:shadow-outline focus:outline-none hover:shadow-xl`}
                  >
                    Proceed To Payment
                  </span>
                </div>
              </div>
            </div>

            <Dialog
              open={openDeleteConfirmDialog}
              onClose={() => {
                setOpenDeleteConfirmDialog(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
                  <span className="inline-block mb-1 sm:mb-4">
                    {"Remove Ticket #" + (selectedTicketIndex + 1)}
                  </span>
                  <div className="h-1 ml-auto duration-300 origin-left transform bg-primary-main scale-x-30 group-hover:scale-x-100" />
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  component={"div"}
                  id="alert-dialog-description"
                >
                  <p className="font-sans text-gray-700 lg:text-sm lg:max-w-md">
                    Are you sure you want to remove this ticket from this order?
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <span
                  tabIndex={0}
                  className="cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none"
                  onClick={() => {
                    setOpenDeleteConfirmDialog(false);
                  }}
                >
                  Cancel
                </span>
                <span
                  tabIndex={0}
                  className="cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md shadow-md border border-primary-main text-white bg-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none hover:shadow-xl"
                  onClick={() => {
                    const tix = values.tickets;
                    tix.splice(selectedTicketIndex, 1);
                    setFieldValue(`tickets`, tix);
                    setOpenDeleteConfirmDialog(false);
                  }}
                >
                  Remove
                </span>
              </DialogActions>
            </Dialog>

            <Dialog
              open={showResetDialog}
              onClose={() => {
                setShowResetDialog(false);
              }}
              aria-labelledby="alert-dialog-title-reset"
              aria-describedby="alert-dialog-description-reset"
            >
              <DialogTitle id="alert-dialog-title-reset">
                <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
                  <span className="inline-block mb-1 sm:mb-4">Reset Form</span>
                  <div className="h-1 ml-auto duration-300 origin-left transform bg-primary-main scale-x-30 group-hover:scale-x-100" />
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  component={"div"}
                  id="alert-dialog-description-reset"
                >
                  <p className="font-sans text-gray-700 lg:text-sm lg:max-w-md">
                    Are you sure you want to clear all the fields?
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <span
                  tabIndex={0}
                  className="cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none"
                  onClick={() => {
                    setShowResetDialog(false);
                  }}
                >
                  Cancel
                </span>
                <span
                  tabIndex={0}
                  className="cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md shadow-md border border-primary-main text-white bg-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none hover:shadow-xl"
                  onClick={() => {
                    setShowResetDialog(false);
                    resetForm();
                  }}
                >
                  Remove
                </span>
              </DialogActions>
            </Dialog>
          </Form>
          <Dialog
            open={openReviewSummary}
            onClose={() => !isSubmitting && setOpenReviewSummary(false)}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
                <span className="inline-block mb-1 sm:mb-4">
                  Ticket Order Summary
                </span>
                <div className="h-1 ml-auto duration-300 origin-left transform bg-primary-main scale-x-30 group-hover:scale-x-100" />
              </div>
            </DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText
                component={"div"}
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <div>
                  <p className="mb-1 mt-1 text-xs font-sans md:text-start text-black font-semibold tracking-wide uppercase">
                    Total Amount to pay:
                  </p>
                  <h5 className="text-end md:text-start font-sans text-black text-3xl font-extrabold my-0 leading-none sm:text-xl">
                    {isEarlyBird && (
                      <span
                        style={{
                          textDecoration: "line-through",
                          opacity: "0.5",
                        }}
                      >
                        Php {values.tickets[0].no_of_tickets * 1000}{" "}
                      </span>
                    )}
                    Php {values.tickets[0].no_of_tickets * price}
                    .00
                  </h5>
                  {isEarlyBird && (
                    <p className="text-sm text-gray-700 font-sans  mb-4">
                      Early bird promo is only applicable for payment submitted
                      before August 31, 2023.
                    </p>
                  )}
                </div>
                <Alert severity="info">
                  <span className="font-sans">
                    <b>Note:</b> Payment will be done via bank transfer. After
                    submitting the form, please send the payment to our bank
                    account and send us the copy of the receipt. Detailed
                    instruction will follow after you submit the form.
                    <br />
                    <br />
                    Also, please make sure that the information you provided is
                    correct as you can no longer able to edit it once submitted.
                    <br />
                  </span>
                </Alert>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <span
                tabIndex={0}
                className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none${
                  isSubmitting
                    ? " bg-gray-200 border-none text-gray-700 opacity-75"
                    : ""
                }`}
                onClick={() => !isSubmitting && setOpenReviewSummary(false)}
              >
                Back to Form
              </span>
              <span
                tabIndex={0}
                className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md shadow-md border border-primary-main text-white bg-primary-main hover:border-blue-accent-700 focus:shadow-outline focus:outline-none hover:shadow-xl${
                  isSubmitting ? " bg-gray-200 border-none text-gray-700" : ""
                }`}
                onClick={() => {
                  !isSubmitting && submitForm();
                }}
              >
                {!isSubmitting ? "Proceed to Payment" : "Proceeding..."}
              </span>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Formik>
  );
}
