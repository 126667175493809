import { useState, useEffect } from "react";
import { motion, stagger, useAnimate, useInView } from "framer-motion";

export default function ConferenceSchedule() {
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope, { once: true });
  const [isDayOne, setIsDayOne] = useState(true);
  useEffect(() => {
    if (isInView) {
      animate(
        ".animate-item",
        {
          opacity: [0, 1],
          y: [20, 0],
        },
        { delay: stagger(0.2, { startDelay: 0.3 }) }
      );
    }
  }, [animate, isInView]);
  return (
    <div
      className="px-4 py-8 mx-auto md:px-24 lg:px-8 lg:py-12 bg-gray-800 overflow-hidden"
      style={{
        backgroundImage: "url(/images/bg-conference.jpg)",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
      ref={scope}
    >
      <div className="grid sm:text-center content-center items-center justify-center gap-6 row-gap-10">
        <h2 className="max-w-lg font-sans text-2xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto animate-item">
          Conference Schedule
        </h2>
        <div
          className="grid w-full justify-self-center max-w-4xl  animate-item"
          style={{
            background: "rgba(255, 255, 255, 0.2)",
            borderRadius: "16px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            border: "1px solid rgba(255, 255, 255, 0.3)",
            overflow: "hidden",
            gridTemplateColumns: "200px 1fr 1fr",
          }}
        >
          <div
            className={`grid center content-center font-sans cursor-pointer font-bold text-xl uppercase text-white px-4 py-2 border-4 ${"bg-accent-light border-accent-main"}`}
          >
            Time
          </div>
          <div
            className={`font-sans cursor-pointer font-bold text-xl uppercase text-white px-4 py-2 border-4 ${"bg-gray-900 border-gray-900"}`}
          >
            Day One: <p className="text-sm">October 27, 2023 - 8am - 5pm</p>
          </div>
          <div
            className={`font-sans cursor-pointer font-bold text-xl uppercase  text-white px-4 py-2 border-4 ${
              !isDayOne
                ? "bg-accent-light border-accent-main"
                : "bg-black border-black"
            }`}
          >
            Day Two: <p className="text-sm">October 28, 2023 - 8am - 5pm</p>
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            8:00am
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-2 bg-[rgba(0,0,0,0.3)] text-center px-4 py-2 font-small text-xl  text-white  animate-item">
            Doors Open
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            9:00am
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-2 bg-[rgba(0,0,0,0.3)] text-center px-4 py-2 font-small text-xl text-white  animate-item">
            Adoratio
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            9:35am
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-1 border-r border-r-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl  text-white  animate-item">
            Plenary 1: Trending Discipleship
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-1 border-r border-r-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl  text-white  animate-item">
            Plenary 4: Discipleship from the Inside Out
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            10:25am
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-2 bg-[rgba(0,0,0,0.3)] text-center px-4 py-2 font-small text-xl  text-white  animate-item">
            Adoratio
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            10:50am
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-1 border-r border-r-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-center text-white  animate-item">
            Plenary 2: The Crisis of Discipleship
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-1 border-r border-r-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-center text-white  animate-item">
            Workshop 2: IDMC Movement
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            11:40am
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-2 bg-[rgba(0,0,0,0.3)] text-center px-4 py-2 font-small text-xl  text-white  animate-item">
            Lunch
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            1:30pm
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-2 bg-[rgba(0,0,0,0.3)] text-center px-4 py-2 font-small text-xl  text-white  animate-item">
            Adoratio
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            2:05pm
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-1 border-r border-r-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-center text-white  animate-item">
            Plenary 3: Demystifying Discipleship
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-1 border-r border-r-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-center text-white  animate-item">
            Plenary 5: Critical Concerns of Today
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            3:20pm
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-2 bg-[rgba(0,0,0,0.3)] text-center px-4 py-2 font-small text-xl  text-white  animate-item">
            Break
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            3:45pm
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-1 border-r border-r-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-center text-white  animate-item">
            Workshop 1: Discipleship That Redefines Success In The Ministry
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-1 border-r border-r-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-center text-white  animate-item">
            Plenary 6: It Takes Leadership
          </div>
          <div className="border-r border-r-[rgba(0,0,0,0.2)] border-b border-b-[rgba(0,0,0,0.2)] px-4 py-2 font-small text-xl text-left text-white  animate-item">
            4:45pm
          </div>
          <div className="border-b border-b-[rgba(0,0,0,0.2)] col-span-2 bg-[rgba(0,0,0,0.3)] text-center px-4 py-2 font-small text-xl  text-white  animate-item">
            Reminders and Closing Remarks
          </div>
        </div>
      </div>
    </div>
  );
}
