import React from "react";

export default function PageBanner() {
  return (
    <div>
      <div className="relative overflow-hidden border-b-[8px] border-b-accent-main">
        <div className="bg-primary-main">
          <img
            src="/images/header-bg.webp"
            className="absolute object-cover w-full h-full top-0 left-0"
            alt=""
          />
          <img
            src="/images/cross.webp"
            className="absolute object-cover w-[50%] translate-x-1/2 top-[-30%] left-0 "
            alt=""
          />
          <div className="relative px-4 py-8 mx-auto lg:py-12 md:px-8 xl:px-4 sm:max-w-xl md:max-w-5xl">
            <div className=" flex items-center justify-center">
              <img
                src="/images/header_text.webp"
                className="w-8/12 max-w-7xl"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
