import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

export default function Speaker() {
  const [isHovered, setIsHovered] = useState(null);

  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const speakersData = [
    {
      name: "Rev Edmund Chan",
      imageSrc: "/images/ps_Ed_final.png",
      job: "Leadership Mentor, Covenant EFC Founder, Global Alliance of IDMC",
      description:
        "Reverend Edmund Chan is a seasoned disciplemaking pastor. The Leadership Mentor of Covenant Evangelical Free Church. Reverend Edmund is widely regarded as an insightful Bible expositor and a mentor of Christian leaders.",
    },
    {
      name: "Pastor Ann Chan",
      imageSrc: "/images/ps_Ann_final.png",
      job: "Associate Pastor, Covenant EFC Director, Global Alliance of IDMC",
      description:
        "Pastor Ann Chan ministers actively alongside her husband, Reverend Edmund Chan, the Leadership Mentor of Covenant Evangelical Free Church (EFC), championing the Intentional Disciplemaking Churches (IDMC) vision around the world.",
    },
    {
      name: "Rev Al Termulo",
      imageSrc: "/images/ps_al_final.jpg",
      job: "CROSSWorld Church Senior Pastor",
      description:
        "Reverend Al Termulo is the Senior Pastor of CROSSWorld Church (Christ Enthroned Church, Inc.). The leadership mentor of CROSSWorld Mission. He is committed to bear the Cross to the World through Authentic Discipleship and Intentional Disciple Making of A Certain Kind.",
    },
    {
      name: "Pastor Citas Termulo",
      imageSrc: "/images/ps_citas_final.jpg",
      job: "CROSSWorld Church - Central Deputy Senior Pastor",
      description:
        "Pastor Citas Termulo is a spiritual mentor and a teacher. Her passionate love for teaching ushered her into designing the curriculum of the church’s equipping programs and training materials. Together with Reverend Al Termulo, they are championing authentic discipleship and intentional discipleship-making of a certain kind.",
    },
  ];
  return (
    <div
      style={{
        backgroundImage:
          "repeating-linear-gradient(45deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),repeating-linear-gradient(135deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),linear-gradient(90deg, hsl(256,7%,84%),hsl(256,7%,84%))",
      }}
    >
      <div className="max-w-screen-xl xl:px-20 px-8 py-20 mx-auto container relative z-40">
        <p
          className="text-4xl sm:text-6xl font-bold text-gray-800 mb-12 text-center animate-item"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          Our Speakers
        </p>
        <div className="grid grid-cols-1 gap-8 lg:gap-24 lg:grid-cols-4 items-start place-items-center">
          {speakersData.map((speaker, index) => (
            <div
              key={index}
              className="grid justify-center place-items-center max-w-[400px] text-center"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="object-cover w-full rounded-full max-w-[200px] md:max-w-[300px] mb-6 animate-item"
                src={speaker.imageSrc}
                alt=""
              />
              <p className="mb-2 text-xl font-bold leading-none sm:text-3xl animate-item">
                {speaker.name}
              </p>
              <p className="mb-4 font-normal leading-none animate-item">
                {speaker.job}
              </p>
              <motion.p
                className="text-gray-700 mt-5 tracking-tighter leading-5 overflow-hidden"
                initial={{ height: 0 }}
                animate={{ height: isHovered === index ? "auto" : 0 }}
                transition={{ duration: 0.5 }}
              >
                {speaker.description}
              </motion.p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
