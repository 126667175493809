import { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { toast } from "react-toastify";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { padStart } from "lodash";

const AppContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState("init");
  const [accountTickets, setAccountTickets] = useState("init");
  const [settings, setSettings] = useState("init");
  const [isEarlyBird, setIsEarlyBird] = useState(false);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const logout = () => {
    signOut(auth);
  };

  const showToast = (message) => {
    toast.success(message);
  };

  const showErrorToast = (message) => {
    toast.error(message);
  };
  const showWarnToast = (message) => {
    toast.warn(message);
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (err) {
      console.log(err);
    }
  };

  const logoutUser = async () => {
    try {
      await logout();
    } catch (err) {
      console.log(err);
    }
  };

  const generateObjects = (count, orderId, value) =>
    Array.from({ length: count }, (_, i) => ({
      [`${orderId}-${(i + 1).toString().padStart(3, "000")}-${value}`]: {
        approvedBy: user?.email,
        dateScanned: new Date(),
      },
    })).reduce((acc, obj) => ({ ...acc, ...obj }), {});

  const addTicket = async (ticketData, baseAmount, selectedType) => {
    const ticket_id = uuid().substring(0, 6).toUpperCase();
    const numberOfTickets = ~~ticketData.tickets[0].no_of_tickets;
    let approvedObject = {};
    if (selectedType === "normal-auto-approved") {
      approvedObject = {
        status: "Paid",
        status_code: "102",
        is_paid: true,
        date_approved: new Date(),
        transaction_ref_no: "",
        transaction_mode: "CASH/GCASH",
        transaction_remarks: "FOR REVALUATION",
        transaction_approver: user?.email,
        transaction_approver_email: user?.email,
      };
    }
    if (selectedType === "normal-auto-approved-and-check-in_day_1") {
      approvedObject = {
        status: "Paid",
        status_code: "102",
        is_paid: true,
        is_reserved: true,
        date_approved: new Date(),
        transaction_ref_no: "",
        transaction_mode: "CASH/GCASH",
        transaction_remarks: "FOR REVALUATION",
        transaction_approver: user?.email,
        transaction_approver_email: user?.email,
        attendance: generateObjects(
          numberOfTickets,
          ticket_id,
          "day_one_attendance"
        ),
      };
    }
    if (selectedType === "normal-auto-approved-and-check-in_day_2") {
      approvedObject = {
        status: "Paid",
        status_code: "102",
        is_paid: true,
        is_reserved: true,
        date_approved: new Date(),
        transaction_ref_no: "",
        transaction_mode: "CASH/GCASH",
        transaction_remarks: "FOR REVALUATION",
        transaction_approver: user?.email,
        transaction_approver_email: user?.email,
        attendance: generateObjects(
          numberOfTickets,
          ticket_id,
          "day_two_attendance"
        ),
      };
    }
    const sampleRequest = {
      ...ticketData.tickets[0],
      baseAmount: baseAmount,
      account_id: user?.uid,
      email: user?.email,
      ticket_id: ticket_id,
      dateAdded: new Date(),
      status: "Pending Payment",
      status_code: "100",
      status_message: "",
      transaction_img_src: "",
      user_message: "",
      is_reserved: true,
      no_of_tickets: numberOfTickets,
      ...approvedObject,
    };

    const tix = doc(collection(db, "tickets"));
    await setDoc(tix, sampleRequest);
    return ticket_id;
  };

  const updateTicketTransaction = async (ticketData, imgUrl, remarks) => {
    let request = {};
    if (ticketData.status_code === "102") {
      request = {
        transaction_img_src: imgUrl,
        date_payment_submitted: new Date(),
        user_message: remarks,
      };
    } else {
      request = {
        status_message: "Your uploaded receipt is under approval",
        status: "Pending Validation",
        status_code: "101",
        transaction_img_src: imgUrl,
        date_payment_submitted: new Date(),
        user_message: remarks,
      };
    }

    const ticketRef = doc(db, "tickets", ticketData.doc_id);
    await setDoc(ticketRef, { ...request }, { merge: true });
  };

  const voidTicket = async (ticketData) => {
    const request = {
      is_reserved: false,
      status: "VOIDED",
      status_message:
        "Ticket automatically cancelled due to unable to submit receipt on time. You can register again. Thank you",
      status_code: "500",
    };

    const ticketRef = doc(db, "tickets", ticketData.doc_id);
    await setDoc(ticketRef, { ...request }, { merge: true });
  };

  useEffect(() => {
    let ticketListenerUnsub = null;
    let settingsUnsub = null;
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser?.uid) {
        const q = query(
          collection(db, "tickets"),
          where("account_id", "==", currentUser.uid)
        );

        settingsUnsub = onSnapshot(
          doc(db, "counters", "reservedTicket"),
          (doc) => {
            console.log("Current data: ", doc.data());
            let earlyBirdDate = new Date("09/01/2023");
            let diff = new Date().getTime() - earlyBirdDate;
            // setIsEarlyBird(diff < 0 && doc.data().count < 200);
            setIsEarlyBird(diff < 0);
            setSettings({ count: doc.data().count > 0 ? doc.data().count : 0 });
          }
        );
        ticketListenerUnsub = onSnapshot(q, (querySnapshot) => {
          const ticket = [];
          querySnapshot.forEach((doc) => {
            ticket.push({ ...doc.data(), doc_id: doc.id });
          });
          setAccountTickets(ticket);
        });
      }
      if (user === null) {
        ticketListenerUnsub && ticketListenerUnsub();
        settingsUnsub && settingsUnsub();
      }
    });
    return () => {
      unsubscribe();
      ticketListenerUnsub && ticketListenerUnsub();
      settingsUnsub && settingsUnsub();
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        addTicket,
        updateTicketTransaction,
        handleGoogleSignIn,
        showWarnToast,
        user,
        accountTickets,
        logoutUser,
        showToast,
        showErrorToast,
        isEarlyBird,
        settings,
        voidTicket,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AppContext);
};
