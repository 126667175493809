import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { UserAuth } from "../../context/AppContext";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

export default function MyTicket() {
  const { accountTickets } = UserAuth();
  const navigate = useNavigate();
  return (
    <>
      <div className="grid gap-8 row-gap-5 lg:grid-cols-3">
        {accountTickets !== "init" &&
          accountTickets?.map((ticket, index) => (
            <div
              key={index}
              onClick={() => {
                navigate("/my-tickets/" + ticket.ticket_id);
              }}
              className="cursor-pointer relative p-px overflow-hidden transition duration-300 transform border border-dashed rounded shadow-sm hover:scale-105 group hover:shadow-xl"
            >
              <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-primary-main group-hover:scale-x-100" />
              <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-primary-main group-hover:scale-y-100" />
              <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-primary-main group-hover:scale-x-100" />
              <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-primary-main group-hover:scale-y-100" />
              <div className="relative p-5 bg-white rounded-sm">
                <dl>
                  <div>
                    <div>
                      <dt className="sr-only">Order ID</dt>
                      <dd className="text-lg font-semibold">
                        Order ticket ID: {ticket.ticket_id}
                      </dd>
                    </div>
                    <dt className="sr-only">Total Price</dt>
                    <dd className="text-md text-gray-700">
                      Php{" "}
                      {(
                        ticket.no_of_tickets * ticket.baseAmount
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </dd>
                  </div>
                </dl>

                <dl className="flex items-center mt-6 space-x-8 text-xs">
                  <div className="sm:inline-flex sm:items-center sm:shrink-0">
                    <ConfirmationNumberIcon
                      fontSize="small"
                      htmlColor="#2979ff"
                    />
                    <div className="sm:ml-3 mt-1.5 sm:mt-0">
                      <dt className="text-gray-500">No. of tickets</dt>
                      <dd className="font-medium">{ticket.no_of_tickets}</dd>
                    </div>
                  </div>
                  <div className="sm:inline-flex sm:items-center sm:shrink-0">
                    <ReceiptLongIcon fontSize="small" htmlColor="#2979ff" />

                    <div className="sm:ml-3 mt-1.5 sm:mt-0">
                      <dt className="text-gray-500">Status</dt>

                      <dd
                        className={
                          "font-medium" +
                          (ticket?.status_code === "500"
                            ? " text-red-400 font-bold"
                            : "")
                        }
                      >
                        {ticket?.status}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          ))}
      </div>
      {accountTickets === "init" && (
        <div className="grid justify-center items-center h-56 w-full">
          <ReactLoading
            type="bubbles"
            color="#2979ff"
            height={150}
            width={150}
          />
        </div>
      )}
      {!accountTickets?.length && (
        <div className="bg-white">
          <div className="lg:flex lg:items-center lg:justify-between w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
            <h2 className="text-3xl font-extrabold text-black sm:text-4xl">
              <span className="block">You don't have any tickets</span>
              <span className="block text-indigo-500">under your account.</span>
            </h2>
            <div className="lg:mt-0 lg:flex-shrink-0">
              <div className=" inline-flex rounded-md shadow">
                <NavLink
                  to="/get-tickets"
                  className="py-4 px-6 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                >
                  Register
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
