import React from "react";
import PageBanner from "../../components/PageBanner/PageBanner";
import RegistrationForm from "../../components/RegistrationForm/RegistrationForm";
export default function MyTicket() {
  return (
    <>
      <PageBanner />
      <RegistrationForm />
    </>
  );
}
