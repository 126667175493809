import React from "react";
import { UserAuth } from "../../context/AppContext";
import ReactLoading from "react-loading";
import GoogleButton from "react-google-button";
import { CircularProgress } from "@mui/material";
import PageBanner from "../PageBanner/PageBanner";

export default function ProtectedPage({
  children,
  title,
  subtitle,
  showButton = true,
}) {
  const { user, handleGoogleSignIn, showToast, showErrorToast } = UserAuth();

  if (user === null) {
    return (
      <>
        <PageBanner />
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
            <div className="flex flex-col items-center mb-16 sm:text-center sm:mb-0">
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                  {title ? title : "You are not authorized to view this page"}
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                  {subtitle
                    ? subtitle
                    : "You need to login first before you can view this page. By logging in, you can now view tickets, order tickets, and more. Make sure you log-in via trusted app such as Google Chrome / Firefox / Safari to avoid any login issues."}
                </p>
              </div>
              {showButton && (
                <>
                  {user === "init" && <CircularProgress />}
                  {user !== "init" && !user?.email && (
                    <GoogleButton onClick={handleGoogleSignIn} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
  if (user === "init") {
    return (
      <>
        <div className="grid justify-center items-center h-96">
          <ReactLoading
            type="bubbles"
            color="#313192"
            height={150}
            width={150}
          />
        </div>
      </>
    );
  }

  return children;
}
